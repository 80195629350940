<template>
  <v-card
    width="400px"
    class="pa-3 pb-0"
  >
    <div class="d-flex justify-space-between">
      <span class="text-subtitle-2 font-weight-bold primary--text">Diarsipkan</span>
      <input-icon-toggle
        class="ms-2"
        :icon="icons.mdiMagnify"
        placeholder="Cari job diarsipkan"
        @input="onSearch"
        @close="closeSearch"
      />
    </div>
    <div
      id="archivedJob"
      v-scroll:#archivedJob="listenScrollFetchMoreJob"
      class="job-list mt-2"
    >
      <JobCard
        v-for="job in jobList"
        :key="job.id"
        :data="job"
        class="mr-1"
        :is-archive="true"
        @restore="restoreJobFunc"
      />
    </div>
  </v-card>
</template>

<script>
import { computed, onMounted, watch } from '@vue/composition-api'
import { mdiMagnify } from '@mdi/js'
import Vue from 'vue'
import InputIconToggle from '@/components/inputs/InputIconToggle.vue'
import JobCard from './JobCard.vue'
import useJob from '@/composables/useJob'
import { archiveJob } from '@/graphql/mutations'
import { apolloClient } from '@/vue-apollo'
import store from '@/store'
import errorHandling from '@/utils/errorHandling'

export default {
  components: {
    InputIconToggle,
    JobCard,
  },
  props: {
    jobs: {
      type: Array,
      default: () => [],
    },
    isArchiveOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const {
      jobList, jobFilter, fetchJobs, listenScrollFetchMoreJob, debouncedFetchJobs,
    } = useJob()
    const isOpen = computed(() => props.isArchiveOpen)
    watch(isOpen, () => {
      jobFilter.value.filter.is_archive = true
      fetchJobs()
    })

    onMounted(() => {
      jobFilter.value.filter.is_archive = true
      fetchJobs()
    })

    const restoreJobFunc = id => {
      Vue.$dialog({
        title: 'Pulihkan job?',
        body: 'Yakin ingin pulihkan job?',
      }).then(result => {
        if (result) {
          apolloClient.mutate({
            mutation: archiveJob,
            fetchPolicy: 'no-cache',
            variables: {
              id,
              is_archive: false,
              workspace_id: store.getters.getCurrentWorkspaceId,
            },
          }).then(() => {
            emit('update')
            Vue.notify({
              title: 'Sukses!',
              text: 'Berhasil memulihkan job!',
            })
          }).catch(err => {
            errorHandling(err)
          })
        }
      })
    }
    const closeSearch = () => {
      jobFilter.value.filter.is_archive = true
      jobFilter.value.filter.search = null
      fetchJobs()
    }

    const onSearch = value => {
      jobFilter.value.filter.search = value
      debouncedFetchJobs()
    }

    return {
      debouncedFetchJobs,
      listenScrollFetchMoreJob,
      restoreJobFunc,
      jobList,
      closeSearch,
      onSearch,
      icons: {
        mdiMagnify,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';

.job-list {
  @include style-scroll-bar();
  max-height: 60vh;
  overflow-y: scroll;
}
</style>
