<template>
  <div>
    <div class="d-flex job-kanban">
      <div
        v-for="(column, index) in jobKanban"
        :key="column.id"
      >
        <kanban-board
          :column="column"
          :index="index"
          :check-move="handleMoveCard"
          :sortable="$can('update', 'JobStatus')"
          @sortColumn="handleColumnUpdate"
          @moveStatus="switchGroup"
        >
          <template #column-header="{ data }">
            <v-hover #default="{ hover: isHovered }">
              <div
                class="kanban-header d-flex justify-space-between"
                :data-column="data.columnData"
              >
                <small class="mb-2">{{ data.columnData.name }}</small>
                <v-menu
                  ref="menu"
                  :close-on-content-click="false"
                  :close-on-click="false"
                  max-width="290px"
                  min-width="290px"
                  offset-y
                  left
                  transition="slide-x-transition"
                >
                  <template v-slot:activator="{ on, attrs, value }">
                    <v-slide-x-reverse-transition mode="out-in">
                      <v-icon
                        v-show="isHovered || value"
                        :key="1"
                        size="24px"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiDotsHorizontal }}
                      </v-icon>
                    </v-slide-x-reverse-transition>
                  </template>
                  <v-card>
                    <v-list>
                      <div class="px-4 mt-2 d-flex justify-space-between">
                        <span class="text-subtitle-2 font-medium">Ubah {{ groupBy.label }}</span>
                        <v-icon
                          size="20"
                          @click="resetColumn(data.index)"
                        >
                          {{ icons.mdiClose }}
                        </v-icon>
                      </div>
                      <div class="px-4 mt-2">
                        <v-text-field
                          v-model="data.columnData.name"
                          :placeholder="`Nama ${groupBy.label}`"
                          hide-details
                          dense
                          @keydown="$event.keyCode === 13 ? updateGroupDetail(data.columnData, data.index) : null"
                        />
                      </div>
                      <div class="px-4 mt-6">
                        <color-picker
                          v-model="data.columnData.color"
                          :label="`Warna ${groupBy.label}`"
                          :attach="false"
                        />
                      </div>
                      <div
                        v-if="groupBy.field === 'status'"
                        class="px-4 mt-4"
                      >
                        <div class="d-flex justify-space-between align-center">
                          <small>Status Selanjutnya</small>
                          <v-icon
                            color="primary"
                            size="18"
                          >
                            {{ icons.mdiHelpCircleOutline }}
                          </v-icon>
                        </div>
                        <v-select
                          v-model="data.columnData.next_job_status_id"
                          :items="data.index + 1 === jobKanban.length ? columnGroup : columnGroup.filter(el => el.id !== data.columnData.id)"
                          multiple
                          chips
                          small-chips
                          hide-details
                          dense
                          item-text="name"
                          item-value="id"
                        >
                          <template #selection="{ item }">
                            <v-chip
                              :color="item.color"
                              small
                              class="mb-1"
                            >
                              {{ item.name }}
                            </v-chip>
                          </template>
                        </v-select>
                      </div>
                    </v-list>
                    <v-divider />
                    <div class="d-flex">
                      <v-btn
                        color="error"
                        text
                        @click="deleteStatus(data.columnData.id, data.index)"
                      >
                        Hapus
                      </v-btn>
                      <v-spacer />
                      <v-btn
                        color="primary"
                        text
                        @click="updateGroupDetail(data.columnData, data.index)"
                      >
                        Simpan
                      </v-btn>
                    </div>
                  </v-card>
                </v-menu>
              </div>
            </v-hover>
          </template>
          <template #kanban-card="{ data }">
            <job-card
              :key="data.id"
              :data="data"
              is-kanban
              @showtask="$emit('showtask', {job: $event, customer: $event.customer})"
              @delete="$emit('delete', $event)"
              @update="$emit('update', $event)"
              @refetch="$emit('refetch')"
            />
          </template>
        </kanban-board>
      </div>
    </div>
    <v-card
      elevation="9"
      class="px-2 kanban-footer"
      width="500px"
    >
      <v-data-footer
        :options.sync="computedOptions"
        :pagination.sync="pagination"
        items-per-page-text="Jumlah data"
        :items-per-page-options="[20, 40, 60]"
        class="kanban-pagination"
      >
        <template
          slot="page-text"
          class="mx-0"
        >
          <span>
            Melihat {{ pagination.pageStart }} - {{ jobKanban.reduce((prev, curr) => prev + curr.lists.length, 0) }} dari total {{ pagination.itemsLength }} data
          </span>
        </template>
      </v-data-footer>
    </v-card>

    <attribute-delete-confirmation
      ref="attributeDeleteConfirmation"
      @update="handleUpdateConfirmation()"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import {
  ref, onMounted, computed, watch,
} from '@vue/composition-api'
import { mdiDotsHorizontal, mdiClose, mdiHelpCircleOutline } from '@mdi/js'
import { useDebounceFn } from '@vueuse/core'
import { apolloClient } from '@/vue-apollo'
import { jobPriority, jobStatus } from '@/graphql/queries'
import {
  deleteJobStatus,
  proceedJobStatus,
  proceedJobPriority,
  updateJobStatus,
  updateJobStatusUrutan,
  updateJobPriority,
} from '@/graphql/mutations'
import KanbanBoard from '@/components/misc/KanbanBoard.vue'
import ColorPicker from '@/components/inputs/ColorPicker.vue'
import JobCard from './JobCard.vue'
import errorHandling from '@/utils/errorHandling'
import store from '@/store'
import AttributeDeleteConfirmation from './AttributeDeleteConfirmation.vue'
import { createFieldMapper } from 'vuex-use-fields'

const useFieldJob = createFieldMapper({ getter: 'job/getField', setter: 'job/setField' })

export default {
  components: {
    KanbanBoard,
    ColorPicker,
    JobCard,
    AttributeDeleteConfirmation,
  },
  props: {
    types: {
      type: Object,
      default: () => {},
    },
    jobs: {
      type: Array,
      default: () => [],
    },
    options: {
      type: Object,
      default: () => {},
      required: true,
    },
    itemsPerPage: {
      type: Number,
      default: null,
      required: true,
    },
    serverItemsLength: {
      type: Number,
      default: null,
      required: true,
    },
  },
  setup(props, { emit }) {
    const menu = ref()
    const attributeDeleteConfirmation = ref()

    const state = { ...useFieldJob(['jobFilter']) }
    const jobKanban = ref([])
    const columnGroup = ref([])
    const loadingFetch = ref(true)

    const jobs = computed(() => props.jobs)
    const groupBy = computed(() => state.jobFilter.value.group)
    const groupOrder = computed(() => state.jobFilter.value.groupOrder)
    const computedOptions = computed({
      get() {
        return props.options
      },
      set(val) {
        emit('pagination', val)
      },
    })
    const pagination = computed(() => {
      const pageCount = props.serverItemsLength / props.itemsPerPage
      const pageStart = props.options.page === 1 ? 0 : (props.options.page - 1) * props.itemsPerPage

      return {
        itemsLength: props.serverItemsLength,
        itemsPerPage: props.itemsPerPage,
        page: props.options.page,
        pageCount,
        pageStart,
        pageStop: pageStart + props.itemsPerPage,
      }
    })

    const renderList = () => {
      if (groupBy.value.field === 'status') {
        jobKanban.value = columnGroup.value.map(el => ({
          id: el.id,
          name: el.name,
          color: el.color,
          type: el.type,
          lists: jobs.value.filter(job => job.status.id === el.id).slice(pagination.value.pageStart, pagination.value.pageStop),
          next_job_status_id: el.job_order?.length
            ? el.job_order.map(order => order.next_job_status_id)
            : [],
        }))
      } else {
        jobKanban.value = columnGroup.value.reduce((acc, el) => {
          acc.push({
            id: el.id,
            name: el.name,
            color: el.color,
            lists: jobs.value.filter(job => job.priority?.id === el.id).slice(pagination.value.pageStart, pagination.value.pageStop),
          })

          return acc
        }, [])

        jobKanban.value.push({
          id: null,
          name: 'Unset',
          color: '#8a8d93',
          lists: jobs.value.filter(job => !job.priority).slice(pagination.value.pageStart, pagination.value.pageStop),
        })
      }
    }

    const fetchJobStatus = id => {
      loadingFetch.value = true
      apolloClient.query({
        query: jobStatus,
        variables: {
          job_type: [id],
          order: groupOrder.value,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
        fetchPolicy: 'no-cache',
      }).then(result => {
        columnGroup.value = result.data.jobStatus
        renderList()
        loadingFetch.value = false
      }).catch(err => {
        loadingFetch.value = false
        errorHandling(err)
      })
    }

    const fetchJobPriority = id => {
      loadingFetch.value = true
      apolloClient.query({
        query: jobPriority,
        fetchPolicy: 'no-cache',
        variables: {
          job_type: [id],
          order: groupOrder.value,
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      }).then(result => {
        columnGroup.value = result.data.jobPriority
        renderList()
        loadingFetch.value = false
      }).catch(err => {
        loadingFetch.value = false
        errorHandling(err)
      })
    }

    const handleUpdateConfirmation = () => {
      emit('refetch')
      fetchJobStatus(props.types.id)
    }

    const handleColumnUpdate = data => {
      apolloClient.mutate({
        mutation: updateJobStatusUrutan,
        variables: {
          params: data.rawData.map((el, index) => ({
            id: el.id,
            urutan: index + 1,
          })),
          workspace_id: store.getters.getCurrentWorkspaceId,
        },
      })
    }

    const updateGroupDetail = (data, index) => {
      if (groupBy.value.field === 'status') {
        apolloClient.mutate({
          mutation: updateJobStatus,
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            job_status: {
              id: data.id,
              name: data.name,
              color: data.color,
              type: data.type,
              next_job_status_id: data.next_job_status_id,
            },
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah status!',
          })
          fetchJobStatus(props.types.id)
          menu.value[index].save()
        }).catch(err => {
          errorHandling(err)
        })
      } else {
        apolloClient.mutate({
          mutation: updateJobPriority,
          variables: {
            workspace_id: store.getters.getCurrentWorkspaceId,
            job_priority: {
              id: data.id,
              name: data.name,
              color: data.color,
            },
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah prioritas!',
          })
          fetchJobPriority(props.types.id)
          menu.value[index].save()
        }).catch(err => {
          errorHandling(err)
        })
      }
    }

    const switchGroup = data => {
      if (groupBy.value.field === 'status') {
        apolloClient.mutate({
          mutation: proceedJobStatus,
          variables: {
            id: data.dragData.element.id,
            status_id: jobKanban.value[data.index].id,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah status job!',
          })
        }).catch(err => {
          errorHandling(err)
          emit('refetch')
        })
      } else {
        apolloClient.mutate({
          mutation: proceedJobPriority,
          variables: {
            id: data.dragData.element.id,
            priority_id: jobKanban.value[data.index].id,
            workspace_id: store.getters.getCurrentWorkspaceId,
          },
        }).then(() => {
          Vue.notify({
            title: 'Sukses!',
            text: 'Berhasil mengubah prioritas job!',
          })
        }).catch(err => {
          errorHandling(err)
          emit('refetch')
        })
      }
    }

    const deleteStatus = id => {
      try {
        if (id === jobKanban.value[0].id || id === jobKanban.value[jobKanban.value.length - 1].id) {
          throw new Error('Tidak dapat menghapus status awal atau akhir')
        }
        Vue.$dialog({
          title: 'Hapus status?',
          body: 'Yakin ingin hapus status ini?',
        }).then(confirm => {
          if (confirm) {
            apolloClient.mutate({
              mutation: deleteJobStatus,
              variables: {
                delete_id: id,
                workspace_id: store.getters.getCurrentWorkspaceId,
              },
            }).then(data => {
              if (data.data.deleteJobStatus.status === 'warning') {
                attributeDeleteConfirmation.value.show(JSON.parse(data.data.deleteJobStatus.data).job, jobKanban.value.filter(el => el.id !== id))
                throw new Error(data.data.deleteJobStatus.msg)
              }
              Vue.notify({
                title: 'Sukses!',
                text: 'Berhasil menghapus status!',
              })
              fetchJobStatus(props.types.id)
            }).catch(err => {
              Vue.notify({
                title: 'Warning',
                text: err.message,
                type: 'warn',
              })
            })
          }
        })
      } catch (error) {
        errorHandling(error)
      }
    }

    const invalidMove = useDebounceFn((fromStatus, toStatus) => {
      Vue.notify({
        title: 'Tidak Valid',
        text: `Tidak diperbolehkan merubah status job dari ${fromStatus} ke ${toStatus}`,
        type: 'warn',
      })
    }, 200)

    const handleMoveCard = data => {
      const fromId = +data.from.parentElement.id
      const toId = +data.to.parentElement.id

      const fromObject = jobKanban.value.find(el => el.id === fromId)

      if (fromObject && fromObject.next_job_status_id && fromObject.next_job_status_id.length) {
        if (!fromObject.next_job_status_id.some(el => el === toId)) {
          invalidMove(fromObject.name, jobKanban.value.find(el => el.id === toId).name)

          return false
        }
      }

      return true
    }

    const fetchGroupColumns = () => {
      if (groupBy.value.field === 'status') fetchJobStatus(props.types.id)
      else fetchJobPriority(props.types.id)
    }

    const resetColumn = index => {
      renderList()
      menu.value[index].save()
    }

    watch(jobs, () => {
      renderList()
    })

    onMounted(() => {
      fetchGroupColumns()
    })

    return {
      menu,
      attributeDeleteConfirmation,

      jobKanban,
      columnGroup,
      loadingFetch,
      computedOptions,
      pagination,
      groupBy,
      groupOrder,

      fetchJobStatus,
      fetchGroupColumns,
      handleColumnUpdate,
      updateGroupDetail,
      resetColumn,
      switchGroup,
      handleUpdateConfirmation,
      handleMoveCard,
      deleteStatus,
      renderList,

      icons: {
        mdiDotsHorizontal,
        mdiClose,
        mdiHelpCircleOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@core/preset/preset/mixins.scss';
@import '~vuetify/src/styles/styles.sass';

  .job-kanban {
    @include style-scroll-bar();
    overflow-x: scroll;
    overflow-y: hidden;
    &::-webkit-scrollbar {
      background: none;
      padding-top: 16px;
    }
  }
  .kanban-footer {
    position: fixed;
    bottom: 16px;
    right: 0;
    margin: 0 24px;
    z-index: 10;

    .kanban-pagination {
      .v-data-footer__select {
        margin-left: 0 !important;
      }

      .v-data-footer__pagination {
        margin-left: auto !important;
      }
    }
  }
</style>
