import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDataFooter } from 'vuetify/lib/components/VDataIterator';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex job-kanban"},_vm._l((_vm.jobKanban),function(column,index){return _c('div',{key:column.id},[_c('kanban-board',{attrs:{"column":column,"index":index,"check-move":_vm.handleMoveCard,"sortable":_vm.$can('update', 'JobStatus')},on:{"sortColumn":_vm.handleColumnUpdate,"moveStatus":_vm.switchGroup},scopedSlots:_vm._u([{key:"column-header",fn:function(ref){
var data = ref.data;
return [_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isHovered = ref.hover;
return [_c('div',{staticClass:"kanban-header d-flex justify-space-between",attrs:{"data-column":data.columnData}},[_c('small',{staticClass:"mb-2"},[_vm._v(_vm._s(data.columnData.name))]),_c(VMenu,{ref:"menu",refInFor:true,attrs:{"close-on-content-click":false,"close-on-click":false,"max-width":"290px","min-width":"290px","offset-y":"","left":"","transition":"slide-x-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
var value = ref.value;
return [_c(VSlideXReverseTransition,{attrs:{"mode":"out-in"}},[_c(VIcon,_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(isHovered || value),expression:"isHovered || value"}],key:1,attrs:{"size":"24px"}},'v-icon',attrs,false),on),[_vm._v(" "+_vm._s(_vm.icons.mdiDotsHorizontal)+" ")])],1)]}}],null,true)},[_c(VCard,[_c(VList,[_c('div',{staticClass:"px-4 mt-2 d-flex justify-space-between"},[_c('span',{staticClass:"text-subtitle-2 font-medium"},[_vm._v("Ubah "+_vm._s(_vm.groupBy.label))]),_c(VIcon,{attrs:{"size":"20"},on:{"click":function($event){return _vm.resetColumn(data.index)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1),_c('div',{staticClass:"px-4 mt-2"},[_c(VTextField,{attrs:{"placeholder":("Nama " + (_vm.groupBy.label)),"hide-details":"","dense":""},on:{"keydown":function($event){$event.keyCode === 13 ? _vm.updateGroupDetail(data.columnData, data.index) : null}},model:{value:(data.columnData.name),callback:function ($$v) {_vm.$set(data.columnData, "name", $$v)},expression:"data.columnData.name"}})],1),_c('div',{staticClass:"px-4 mt-6"},[_c('color-picker',{attrs:{"label":("Warna " + (_vm.groupBy.label)),"attach":false},model:{value:(data.columnData.color),callback:function ($$v) {_vm.$set(data.columnData, "color", $$v)},expression:"data.columnData.color"}})],1),(_vm.groupBy.field === 'status')?_c('div',{staticClass:"px-4 mt-4"},[_c('div',{staticClass:"d-flex justify-space-between align-center"},[_c('small',[_vm._v("Status Selanjutnya")]),_c(VIcon,{attrs:{"color":"primary","size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiHelpCircleOutline)+" ")])],1),_c(VSelect,{attrs:{"items":data.index + 1 === _vm.jobKanban.length ? _vm.columnGroup : _vm.columnGroup.filter(function (el) { return el.id !== data.columnData.id; }),"multiple":"","chips":"","small-chips":"","hide-details":"","dense":"","item-text":"name","item-value":"id"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c(VChip,{staticClass:"mb-1",attrs:{"color":item.color,"small":""}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true),model:{value:(data.columnData.next_job_status_id),callback:function ($$v) {_vm.$set(data.columnData, "next_job_status_id", $$v)},expression:"data.columnData.next_job_status_id"}})],1):_vm._e()]),_c(VDivider),_c('div',{staticClass:"d-flex"},[_c(VBtn,{attrs:{"color":"error","text":""},on:{"click":function($event){return _vm.deleteStatus(data.columnData.id, data.index)}}},[_vm._v(" Hapus ")]),_c(VSpacer),_c(VBtn,{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.updateGroupDetail(data.columnData, data.index)}}},[_vm._v(" Simpan ")])],1)],1)],1)],1)]}}],null,true)})]}},{key:"kanban-card",fn:function(ref){
var data = ref.data;
return [_c('job-card',{key:data.id,attrs:{"data":data,"is-kanban":""},on:{"showtask":function($event){return _vm.$emit('showtask', {job: $event, customer: $event.customer})},"delete":function($event){return _vm.$emit('delete', $event)},"update":function($event){return _vm.$emit('update', $event)},"refetch":function($event){return _vm.$emit('refetch')}}})]}}],null,true)})],1)}),0),_c(VCard,{staticClass:"px-2 kanban-footer",attrs:{"elevation":"9","width":"500px"}},[_c(VDataFooter,{staticClass:"kanban-pagination",attrs:{"options":_vm.computedOptions,"pagination":_vm.pagination,"items-per-page-text":"Jumlah data","items-per-page-options":[20, 40, 60]},on:{"update:options":function($event){_vm.computedOptions=$event},"update:pagination":function($event){_vm.pagination=$event}}},[_c('template',{staticClass:"mx-0",slot:"page-text"},[_c('span',[_vm._v(" Melihat "+_vm._s(_vm.pagination.pageStart)+" - "+_vm._s(_vm.jobKanban.reduce(function (prev, curr) { return prev + curr.lists.length; }, 0))+" dari total "+_vm._s(_vm.pagination.itemsLength)+" data ")])])],2)],1),_c('attribute-delete-confirmation',{ref:"attributeDeleteConfirmation",on:{"update":function($event){return _vm.handleUpdateConfirmation()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }